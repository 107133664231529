<template>
  <div class="vac-room-header vac-app-border-b">
    <slot name="room-header">
      <div class="vac-room-wrapper">
        <transition name="vac-slide-up">
          <div
            v-if="messageSelectionEnabled"
            class="vac-room-selection"
          >
            <div
              v-for="action in messageSelectionActions"
              :id="action.name"
              :key="action.name"
            >
              <div
                class="vac-selection-button"
                @click="messageSelectionActionHandler(action)"
              >
                {{ action.title }}
                <span class="vac-selection-button-count">
                  {{ selectedMessagesTotal }}
                </span>
              </div>
            </div>
            <div
              class="vac-selection-cancel vac-item-clickable"
              @click="$emit('cancel-message-selection')"
            >
              {{ $t('Cancelar') }}
            </div>
          </div>
        </transition>
        <template
          v-if="!messageSelectionEnabled && messageSelectionAnimationEnded"
        >
          <div
            v-if="!singleRoom"
            class="vac-svg-button vac-toggle-button"
            :class="{
              'vac-rotate-icon-init': !isMobile,
              'vac-rotate-icon': !showRoomsList && !isMobile
            }"
            @click="$emit('toggle-rooms-list')"
          >
            <slot name="toggle-icon">
              <svg-icon name="toggle" />
            </slot>
          </div>
          <div
            class="vac-info-wrapper"
            :class="{ 'vac-item-clickable': roomInfoEnabled }"
          >
            <slot name="room-header-avatar">
              <div
                v-if="room.avatar"
                class="vac-avatar"
                :style="{ 'background-image': `url('${room.avatar}')` }"
                @click="$emit('room-info')"
              />
              <div
                v-else
                @click="$emit('room-info')"
              >
                <v-sheet
                  :color="isClosedConversation ? 'grey darken-3' : 'blue'"
                  elevation="1"
                  height="35"
                  width="35"
                  class="mr-1 nametag cursor-pointer"
                >
                  <span
                    v-if="!startsWithEmoji && contactLetters.length"
                    class="avatar-letter"
                  >
                    {{ contactLetters }}
                  </span>
                  <span v-else>
                    <v-icon class="avatar-letter">
                      fas fa-user
                    </v-icon>
                  </span>
                </v-sheet>
              </div>
            </slot>
            <slot name="room-header-info">
              <div class="vac-text-ellipsis cursor-pointer">
                <div class="vac-room-name vac-text-ellipsis">
                  <span
                    @click="$emit('room-info')"
                  >
                    {{ roomName | truncate(30, '...') }}
                  </span>
                  <template v-if="fiveContactTags.length && tags.length">
                    <v-chip
                      v-for="tag in fiveContactTags"
                      :key="tag"
                      small
                    >
                      <v-avatar left>
                        <v-sheet
                          :color="tagColor(tag)"
                          elevation="1"
                          height="18"
                          width="18"
                          style="border-radius: 50%;"
                        />
                      </v-avatar>
                      {{ tagName(tag) }}
                    </v-chip>
                    <span
                      v-if="contactTags.length > 5"
                      class="mt-2"
                    >
                      ...
                    </span>
                  </template>
                  <v-chip
                    v-if="showcloseReasonTag && isClosedConversation"
                    color="grey darken-3"
                    small
                    class="ml-5"
                  >
                    <v-icon
                      size="16"
                      color="white"
                      class="pl-4"
                    >
                      fas fa-box-archive
                    </v-icon>
                    <span class="px-4 text-white">
                      {{ room.lastMessage.content }}
                    </span>
                    <v-icon
                      size="16"
                      color="white"
                      class="pl-2"
                      @click.stop="openConversation"
                    >
                      fas fa-close
                    </v-icon>
                  </v-chip>
                  <v-chip
                    v-if="isContactUnread(room)"
                    color="blue"
                    small
                    class="ml-5"
                    close
                    outlined
                    @click:close="toggleAsRead"
                  >
                    <v-icon
                      size="16"
                      class="pl-4"
                    >
                      far fa-comment
                    </v-icon>
                    <span class="px-3">
                      {{ $t('Interacción pendiente') }}
                    </span>
                  </v-chip>
                  <v-chip
                    v-show="totalNotes > 0"
                    color="blue lighten-3"
                    small
                    class="ml-5"
                    @click="openContactNote"
                  >
                    <v-icon
                      size="16"
                      class="pl-4"
                    >
                      far fa-file
                    </v-icon>
                    <span class="px-3">
                      {{ $t('Notas') }}
                    </span>
                  </v-chip>
                </div>
                <div
                  v-if="typingUsers"
                  class="vac-room-info vac-text-ellipsis"
                >
                  {{ typingUsers }}
                </div>
                <div
                  v-else
                  class="vac-room-info vac-text-ellipsis"
                >
                  <!-- {{ userStatus }} -->
                  <span v-if="contactWasEdited">
                    {{ $t('Fecha de última edición: ') }}{{ contactLastEdition | dateLocal }}
                  </span>
                  <span
                    v-else
                    @click="$emit('room-info')"
                  >
                    {{ $t('Haz click aquí para editar el contacto') }}
                  </span>
                </div>
              </div>
            </slot>
          </div>
          <div>
            <v-select
              v-if="readyAgentSelector"
              v-model="currentAgent"
              :items="chatProps.agents"
              solo
              return-object
              dense
              flat
              filled
              class="mt-7 mr-2 oulined-input"
              style="width: 210px;"
              :disabled="chatProps.agents.length === 0"
              @change="changeCurrentAssistant"
            >
              <template #selection="{ item }">
                <v-icon
                  v-if="item.typeId === 0"
                  class="mr-4 ml-1"
                  color="blue darken-1"
                  size="20"
                >
                  fas fa-user
                </v-icon>
                <v-img
                  v-else
                  :src="require('@/assets/img/nia/nia.png')"
                  max-width="28px"
                  class="mr-2"
                />
                {{ item.name }}
              </template>
              <template #item="{ item }">
                <v-icon
                  v-if="item.typeId === 0"
                  class="mr-4 ml-1"
                  color="blue darken-1"
                  size="20"
                >
                  fas fa-user
                </v-icon>
                <v-img
                  v-else
                  :src="require('@/assets/img/nia/nia.png')"
                  max-width="28px"
                  class="mr-2"
                />
                {{ item.name }}
              </template>
            </v-select>
          </div>
          <div>
            <v-btn-toggle rounded>
              <v-tooltip
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    :style="{ height: '35px !important', width: '60px' }"
                    v-bind="attrs"
                    @click="preCloseConversation"
                    v-on="on"
                  >
                    <v-icon
                      size="16"
                      :color="isClosedConversation ? 'grey darken-3' : 'blue-grey lighten-2'"
                    >
                      fas fa-box-archive
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('Cerrar conversación') }}
                </span>
              </v-tooltip>
              <v-tooltip
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    :style="{ height: '35px !important', width: '60px' }"
                    v-bind="attrs"
                    @click="preAddToBlackList"
                    v-on="on"
                  >
                    <v-icon
                      size="16"
                      :color="(isBlocklisted? 'red' : 'blue-grey') + ' lighten-2'"
                    >
                      fas fa-user-slash
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('Bloquear contacto') }}
                </span>
              </v-tooltip>
              <v-tooltip
                top
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-show="!showSearch"
                    icon
                    :style="{ height: '35px !important', width: '60px' }"
                    v-bind="attrs"
                    @click="openSearch"
                    v-on="on"
                  >
                    <v-icon
                      size="16"
                      color="blue-grey lighten-2"
                    >
                      fas fa-search
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('Búsqueda') }}
                </span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
          <div
            v-show="showSearch"
            class="vac-box-search"
          >
            <div
              class="vac-icon-search"
            >
              <slot name="search-icon">
                <svg-icon name="search" />
              </slot>
            </div>
            <input
              id="searchTextInput"
              v-model="searchText"
              type="search"
              :placeholder="$t('Buscar')"
              autocomplete="off"
              class="vac-input"
              @keyup.enter="onEnter"
            >
          </div>
          <span
            v-show="showSearch"
            style="font-size: 13px;"
          >
            {{ searchResults === 0 ? 0 : (currentFoundMessageIndex + 1) }}/{{ searchResults }}
          </span>
          <v-btn
            v-show="showSearch"
            icon
            style="font-size: 23px;"
            :disabled="searchResults === 0"
            @click="prevSearch"
          >
            <v-icon size="15">
              fas fa-chevron-left
            </v-icon>
          </v-btn>

          <v-btn
            v-show="showSearch"
            icon
            style="font-size:23px;"
            :disabled="searchResults === 0"
            @click="nextSearch"
          >
            <v-icon size="15">
              fas fa-chevron-right
            </v-icon>
          </v-btn>

          <v-btn
            v-show="showSearch"
            icon
            style="font-size:23px;"
            @click="closeSearch"
          >
            <v-icon size="15">
              fas fa-close
            </v-icon>
          </v-btn>

          <slot
            v-if="room.roomId"
            name="room-options"
          >
            <div
              v-if="menuActions.length"
              class="vac-svg-button vac-room-options"
              @click="menuOpened = !menuOpened"
            >
              <slot name="menu-icon">
                <svg-icon name="menu" />
              </slot>
            </div>
            <transition
              v-if="menuActions.length"
              name="vac-slide-left"
            >
              <div
                v-if="menuOpened"
                v-click-outside="closeMenu"
                class="vac-menu-options"
              >
                <div class="vac-menu-list">
                  <div
                    v-for="action in menuActions"
                    :key="action.name"
                  >
                    <div
                      class="vac-menu-item"
                      @click="menuActionHandler(action)"
                    >
                      {{ action.title }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </slot>
        </template>
      </div>
    </slot>

    <v-dialog
      v-model="dialogConfirmCloseConversation"
      style="z-index:30001"
      width="650px"
    >
      <v-card class="nocard">
        <div class="header_modal gradient-text-color">
          <span>
            {{ $t('Cerrar conversación') }}
          </span>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                class="text-white float-right text-white"
                color="transparent"
                elevation="0"
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="dialogConfirmCloseConversation = false"
              >
                <v-icon size="26">
                  far fa-times-circle
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Cerrar') }}</span>
          </v-tooltip>
        </div>
        <v-card-text class="mt-8">
          <v-row>
            <v-col>
              <p class="text-center">
                {{ $t('¿Estás seguro de cerrar la conversación?') }}
              </p>
              <v-select
                v-model="closeReason"
                filled
                :label="$t('razón de cierre')"
                :items="closeReasons"
                return-object
                item-text="name"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            elevation="0"
            large
            text
            color="blue-grey darken-4 white--text"
            class="text-capitalize float-right"
            @click="dialogConfirmCloseConversation = false"
          >
            {{ $t('Cancelar') }}
          </v-btn>
          <v-btn
            elevation="0"
            large
            class="white--text text-capitalize float-right px-5"
            color="green darken-2"
            :disabled="!closeReason"
            @click="closeConversation"
          >
            {{ $t('Cerrar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <room-blocklist
      v-model="dialogConfirmBlacklist"
      :contact-definitive-blocklist="contactDefinitiveBlocklist"
      :blocklist-status="blocklistStatus"
      @modify-blacklist="modifyBlacklist"
      @close-dialog="closeDialogBlocklist"
    />
  </div>
</template>

<script>
  import SvgIcon from '../../../components/SvgIcon/SvgIcon'
  import vClickOutside from 'v-click-outside'
  import typingText from '../../../utils/typing-text'
  import UserInboxCloseReasonService from '@/services/whatsapp/user-Inbox-close-reason.service.js'
  import EventBus from '@/util/EventBus'
  import { Contact } from '@/models/whatsapp/Contact'
  import { BlocklistType } from '@/models/whatsapp/BlocklistType'
  import RoomBlocklist from './RoomBlocklist/RoomBlocklist.vue'
  import SessionsService from '@/services/whatsapp/sessions.service.js'
  import AiService from '@/services/ai/ai.service.js'
  import { ChatProps } from '@/classes/chat/chat-props.js'
  import { Agent } from '@/classes/chat/agent'

  export default {
    name: 'RoomHeader',
    components: {
      SvgIcon,
      RoomBlocklist,
    },

    directives: {
      clickOutside: vClickOutside.directive,
    },

    props: {
      currentUserId: { type: [String, Number], required: true },
      textMessages: { type: Object, required: true },
      singleRoom: { type: Boolean, required: true },
      showRoomsList: { type: Boolean, required: true },
      isMobile: { type: Boolean, required: true },
      roomInfoEnabled: { type: Boolean, required: true },
      menuActions: { type: Array, required: true },
      room: { type: Object, required: true },
      messageSelectionEnabled: { type: Boolean, required: true },
      messageSelectionActions: { type: Array, required: true },
      selectedMessagesTotal: { type: Number, required: true },
      tags: { type: Array, default: () => [] },
      routes: { type: Object },
      chatProps: { type: Object, required: true, default: () => { return new ChatProps() } },
      totalNotes: {
        type: Number,
        default: 0,
      },
    },

    emits: [
      'toggle-rooms-list',
      'room-info',
      'menu-action-handler',
      'cancel-message-selection',
      'message-selection-action-handler',
    ],

    data () {
      return {
        menuOpened: false,
        messageSelectionAnimationEnded: true,
        searchText: '',
        searchResults: 0,
        foundElements: [],
        currentFoundMessageIndex: -1,
        showSearch: false,
        dialogConfirmCloseConversation: false,
        dialogConfirmBlacklist: false,
        dialogRemoveBlacklist: false,
        closeReasons: [],
        closeReason: null,
        selectedBlacklistType: null,
        showcloseReasonTag: false,
        contactDefinitiveBlocklist: {
          list: [],
        },
        agents: [],
        currentAgent: new Agent(),
        user: {},
        readyAgentSelector: true,
      }
    },
    computed: {
      typingUsers () {
        return typingText(this.room, this.currentUserId, this.textMessages)
      },
      userStatus () {
        if (!this.room.users || this.room.users.length !== 2) return

        const user = this.room.users.find(u => u._id !== this.currentUserId)

        if (!user?.status) return

        let text = ''

        if (user.status.state === 'online') {
          text = this.$t('En linea')
        } else if (user.status.lastChanged) {
          text = this.$t('ultima vez visto') + user.status.lastChanged
        }

        return text
      },
      contactLetters () {
        if (!this.room || !this.room.users || this.room.users.length === 0) return ''
        let contact = this.room.users[1].contact
        if (!(contact instanceof Contact)) {
          contact = new Contact(contact)
        }
        return (contact.firstName?.charAt(0).toUpperCase() ?? '') + (contact.lastName?.charAt(0).toUpperCase() ?? '')
      },
      startsWithEmoji () {
        if (!this.room || !this.room.users || this.room.users.length === 0) return ''
        let contact = this.room.users[1].contact
        if (!(contact instanceof Contact)) {
          contact = new Contact(contact)
        }
        const emojiRegex = /^\p{Extended_Pictographic}/u
        return emojiRegex.test(contact.firstName)
      },
      contactTags () {
        const cTags = this.room.users[1].contact.tags
        if (typeof cTags === 'string') {
          return JSON.parse(cTags)
        } else {
          return cTags
        }
      },
      fiveContactTags () {
        return this.contactTags?.slice(0, 5) ?? []
      },
      contactWasEdited () {
        return !!this.room.users[1].contact.updated_at
      },
      contactLastEdition () {
        let lastUpdate = this.room.users[1].contact.updated_at
        lastUpdate = lastUpdate.replace('T', ' ').replace(/\..+/, '')
        return lastUpdate
      },
      roomName () {
        console.log('room', this.room)
        const contact = this.room.users[1]?.contact
        return this.room.roomName?.trim().length ? this.room.roomName : (contact.msisdnClient ?? contact.msisdn_client)
      },
      roomStatus () {
        return this.room.lastMessage?.status
      },
      isBlocklisted () {
        return this.room.blocklist_status?.length
      },
      blacklistTypes () {
        return [
          { value: BlocklistType.MARKETING_ID, text: this.$t('Marketing') },
          { value: BlocklistType.UTILITY_ID, text: this.$t('Utilidad') },
          { value: BlocklistType.AUTHENTICATION_ID, text: this.$t('Autenticación') },
          { value: BlocklistType.ALL_ID, text: this.$t('Todas') },
        ]
      },
      blocklistStatus () {
        return this.room.blocklist_status ?? []
      },
      isClosedConversation () {
        const isClosed = this.roomStatus === 0
        if (isClosed) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.showcloseReasonTag = true
        }
        return isClosed
      },
    },

    watch: {
      searchText () {
        this.cleanClassFound()
        if (this.searchText === '') {
          this.searchResults = 0
          return
        }

        var messagesContainer = document.getElementById('messages-list').getElementsByClassName('vac-format-container')
        let results = 0
        const foundElements = []
        messagesContainer.forEach((element) => {
          if (element.outerText.toLowerCase().trim().includes(this.searchText.toLowerCase().trim())) {
            foundElements.push(element)
            results += 1
          }
        })

        this.foundElements = foundElements
        this.searchResults = results
        if (this.searchResults > 0) {
          this.currentFoundMessageIndex = -1
          this.nextSearch()
        }
      },
      messageSelectionEnabled (val) {
        if (val) {
          this.messageSelectionAnimationEnded = false
        } else {
          setTimeout(() => {
            this.messageSelectionAnimationEnded = true
          }, 300)
        }
      },
      // roomStatus: function (newVal) {
      //   if (this.roomStatus === 0) {
      //     this.showcloseReasonTag = false
      //   }
      // },
    },
    mounted () {
      EventBus.$on('changedClosedReasons', () => {
        this.loadCloseReasons()
      })
      EventBus.$on('resetSearchWhatsappInbox', () => {
        this.resetSearch()
        this.closeSearch()
      })
      this.loadCloseReasons()

      this.user = JSON.parse(localStorage.getItem('user'))
      EventBus.$on('last-session-agent-conversation', this.checkLastSessionAgent)

      EventBus.$on('WhatsAppAgent::changeToUser', () => {
        this.currentAgent = this.chatProps.agents[0]
        this.changeCurrentAssistant()
      })
    },

    methods: {
      changeCurrentAssistant () {
        const contact = this.room.users[1].contact
        AiService.changeAgent({
          contactId: contact.id,
          agent: this.currentAgent,
          msisdnClient: this.room.users[1].contact.msisdnClient,
          msisdnUser: this.room.users[1].contact.msisdnUser,
        }).then((res) => {
          EventBus.$emit('Whatsapp:addChangeAgentMessage', this.currentAgent)
          EventBus.$emit('showAlert', 'success', this.$t('Se ha cambiado de agente correctamente.').toString())
        }, () => {
          this.currentAgent = this.chatProps.currentAgent
        })
      },
      checkLastSessionAgent () {
        this.readyAgentSelector = false
        this.currentAgent = JSON.parse(JSON.stringify(this.chatProps.currentAgent))
        setTimeout(() => {
          this.readyAgentSelector = true
        })
      },
      openConversation () {
        this.searchText = ''
        this.showcloseReasonTag = false
        this.$emit('openClosedConversation', { room: this.room })
      },
      openContactNote () {
        this.$emit('showNoteDialogList')
      },
      closeDialogBlocklist () {
        this.dialogConfirmBlacklist = false
      },
      tagName (tag) {
        return this.tags.length && this.tags.find(t => t.id === tag)?.name
      },
      tagColor (tag) {
        return this.tags.length && this.tags.find(t => t.id === tag)?.color
      },
      loadCloseReasons () {
        UserInboxCloseReasonService.getAllReasons({
          msisdn: this.chatProps.currentUserMsisdn,
        }).then((response) => {
          this.closeReasons = response
        })
      },
      openSearch () {
        this.resetSearch()
        this.showSearch = true
        setTimeout(() => {
          document.getElementById('searchTextInput').focus()
        })
      },
      closeSearch () {
        this.cleanClassFound()
        this.showSearch = false
      },
      resetSearch () {
        this.searchText = ''
      },
      preAddToBlackList () {
        SessionsService.getBlockListFromMsisdnUserAndClient({
          msisdnClient: this.room.users[1].contact.msisdnClient,
          msisdnUser: this.room.users[1].contact.msisdnUser,
        }).then((contactBlocklist) => {
          this.contactDefinitiveBlocklist.list = contactBlocklist
          this.dialogConfirmBlacklist = true
        })
      },
      modifyBlacklist (blocklistTypes) {
        this.dialogConfirmBlacklist = false
        this.$emit('modifyBlacklist', this.room, blocklistTypes)
      },
      preCloseConversation () {
        this.dialogConfirmCloseConversation = true
      },
      closeConversation () {
        this.dialogConfirmCloseConversation = false
        this.$emit('closeConversation', { room: this.room, closeReason: this.closeReason })
      },
      prevSearch () {
        if (this.currentFoundMessageIndex === 0) {
          this.currentFoundMessageIndex = this.foundElements.length - 1
        } else {
          this.currentFoundMessageIndex = this.currentFoundMessageIndex - 1
        }
        this.goToMessage(this.currentFoundMessageIndex)
      },
      nextSearch () {
        this.onEnter()
      },
      onEnter () {
        if (this.searchResults > 0) {
          if (this.currentFoundMessageIndex === this.foundElements.length - 1) {
            this.currentFoundMessageIndex = 0
          } else {
            this.currentFoundMessageIndex = this.currentFoundMessageIndex + 1
          }
          // console.log(this.currentFoundMessageIndex)
          this.goToMessage(this.currentFoundMessageIndex)
        }
      },
      cleanClassFound () {
        this.foundElements.forEach(element => {
          element.classList.remove('found-text-conversation')
        })
      },
      goToMessage (index) {
        this.cleanClassFound()
        this.foundElements[index].classList.add('found-text-conversation')
        const toPosition = this.findPosition(this.foundElements[index]) - 300
        document.getElementById('messages-list').scroll({
          top: toPosition,
          left: 0,
          behavior: 'smooth',
        })
      },
      findPosition (obj) {
        var currenttop = 0
        if (obj.offsetParent) {
          do {
            currenttop += obj.offsetTop
          } while ((obj = obj.offsetParent))
          return [currenttop]
        }
      },
      menuActionHandler (action) {
        this.closeMenu()
        this.$emit('menu-action-handler', action)
      },
      closeMenu () {
        this.menuOpened = false
      },
      messageSelectionActionHandler (action) {
        this.$emit('message-selection-action-handler', action)
      },
      toggleAsRead (conversation) {
        this.$emit('toggleAsRead')
      },
      isContactUnread (room) {
        return !room.users[1].contact.read
      },
    },
  }
</script>

<style scoped>
.nametag {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-letter {
  display: block;
  color: white !important;
  font-size: 18px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
